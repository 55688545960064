import { get, post, put } from "@/application/api";
import { AxiosResponse } from "axios";
import { ArticleGroup } from "@/modules/product/enum/ArticleGroup";
import { TradingUnit } from "@/modules/product/enum/TradingUnit";

export interface Product {
  articleNumber: string;
  name: string;
  unit: string;
  ean: string;
  weight: number;
  length: number;
  width: number;
  customsCode: number;
  hasExpirationDate: boolean;
  commercialExpirationDayOffset: number;
  hu: TradingUnit;
  quantitySku: number;
  huLength: number;
  huWidth: number;
  huHeight: number;
  huEan: string;
  allowBrokenUnit: boolean;
  minimumStock: number;
  hasLotNumbering: boolean;
  hasSerialNumber: boolean;
  serialNumberMask: string;
  fixedSerialNumber: string;
  articleGroup: ArticleGroup;
  description: string;
  customsPrice: number;
  productType: string;
  costCenter: string;
  isActive: boolean;
}

export const conceptSave = ({
  product,
  clientId,
}: {
  product: Product;
  clientId: number;
}): Promise<AxiosResponse> =>
  post(`client/${clientId}/product/concept`, product);

export const conceptSaveById = ({
  productId,
  product,
  clientId,
}: {
  product: Product;
  productId: number;
  clientId: number;
}): Promise<AxiosResponse> =>
  put(`client/${clientId}/product/concept/${productId}`, product);

export const send = ({
  product,
  clientId,
}: {
  product: Product;
  clientId: number;
}): Promise<AxiosResponse> => {
  return post(`client/${clientId}/product`, product);
};

export const sendById = ({
  productId,
  product,
  clientId,
}: {
  product: Product;
  productId: number;
  clientId: number;
}): Promise<AxiosResponse> => {
  return post(`client/${clientId}/product/concept/${productId}`, product);
};

export const conceptProductShow = ({
  clientId,
  productId,
}: {
  clientId: number;
  productId: number;
}): Promise<
  AxiosResponse<{
    data: Product;
    isManual?: boolean;
    validationErrors: { message: string; errors: Record<string, string[]> };
  }>
> => get(`client/${clientId}/product/concept/${productId}`);
