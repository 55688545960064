
import Vue from "vue";
import KForm from "@/components/KForm.vue";
import { AxiosResponse } from "axios";
import eventBus from "@/application/eventBus";
import EventBus from "@/application/eventBus";
import {
  conceptSave,
  conceptSaveById,
  Product,
  conceptProductShow,
  send,
  sendById,
} from "@/modules/product/api/productSave";
import { mapGetters, mapMutations } from "vuex";
import RequiredClientDialog from "@/modules/client/components/RequiredClientDialog.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import KCheckbox from "@/components/crud/fields/KCheckbox.vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import {
  getTranslatedEnumOptions,
  TranslatedEnumOptions,
} from "@/application/util/enum";
import { ArticleGroup } from "@/modules/product/enum/ArticleGroup";
import { Unit } from "@/modules/product/enum/Unit";
import { TradingUnit } from "@/modules/product/enum/TradingUnit";
import { ProductType } from "@/modules/product/enum/ProductType";
import dayjs from "dayjs";

interface ComponentData {
  isLoading: boolean;
  values: Partial<Product>;
  isLoadingSave: boolean;
  isFormDirty: boolean;
  unitOptions: TranslatedEnumOptions;
  articleGroupOptions: TranslatedEnumOptions;
  tradingUnitOptions: TranslatedEnumOptions;
  ProductType: typeof ProductType;
}

export default Vue.extend({
  name: "productForm",
  components: {
    KSelect,
    KCheckbox,
    KTextField,
    RequiredClientDialog,
    KForm,
  },
  data: (): ComponentData => ({
    isLoading: true,
    values: {},
    isFormDirty: false,
    isLoadingSave: false,
    unitOptions: getTranslatedEnumOptions(Unit, "product.unit"),
    articleGroupOptions: getTranslatedEnumOptions(
      ArticleGroup,
      "product.articleGroup"
    ),
    tradingUnitOptions: getTranslatedEnumOptions(TradingUnit, "product.unit"),
    ProductType,
  }),
  computed: {
    ...mapGetters("authorisation", ["client", "isClient"]),
    isUpdateForm(): boolean {
      return this.$route.params.productId !== undefined;
    },
    clientId(): number {
      if (this.isUpdateForm) {
        return parseInt(this.$route.params.clientId as string);
      }
      return this.client?.id;
    },
    productTypeOptions(): TranslatedEnumOptions {
      const hiddenFromClient: ProductType[] = [
        ProductType.IgnoreProduct,
        ProductType.Electronic,
      ];

      return getTranslatedEnumOptions(
        this.isClient
          ? Object.fromEntries(
              Object.entries(ProductType).filter(([_, value]) => {
                return (
                  !hiddenFromClient.includes(value) ||
                  value === this.values.productType
                );
              })
            )
          : ProductType,
        "product.productType"
      );
    },
  },
  watch: {
    "$route.params.productId": {
      immediate: true,
      async handler(id: number): Promise<void> {
        if (id !== undefined) {
          try {
            this.isLoading = true;
            const response = await conceptProductShow({
              productId: id,
              clientId: this.clientId,
            });
            if (response.data.validationErrors) {
              setTimeout(() => {
                Object.keys(response.data.validationErrors.errors).forEach(
                  (key) => {
                    this.addValidationErrorToStore({
                      key,
                      message: response.data.validationErrors.errors[key][0],
                    });
                  }
                );
                (this.$refs.form as any).$refs.form.validate();
              }, 500);
            }

            this.resetProduct();
            this.values = {
              ...this.values,
              ...response.data.data,
              unit: response.data.data.unit || undefined,
              hasLotNumbering: response.data.data.hasLotNumbering || false,
              hasSerialNumbering:
                response.data.data.hasSerialNumbering || false,
              productType:
                response.data.data.productType || ProductType.IgnoreProduct,
            };
            this.$nextTick(() => {
              this.isFormDirty = false;
            });
          } catch (error: any) {
            if (error.response?.status === 404) {
              this.$router.push({ name: "notFound" });
              return;
            }
            throw error;
          }
        }
        this.isLoading = false;
      },
    },
    values: {
      deep: true,
      handler(): void {
        this.isFormDirty = true;

        if (this.values.serialNumberMask){
          this.values.serialNumberMask = 'x'.repeat(this.values.serialNumberMask.length);
        }
      },
    },
    "client.id": {
      handler(newValue: number, oldValue: number): void {
        if (!oldValue) return;
        this.$router.push({
          name: "product.concept",
        });
      },
    },
  },
  created() {
    this.resetProduct();
  },
  methods: {
    ...mapMutations("error", { addValidationErrorToStore: "add" }),
    resetProduct() {
      this.values = {
        unit: Unit.Piece,
        articleGroup: ArticleGroup.General,
        allowBrokenUnit: false,
        hasLotNumbering: false,
        hasSerialNumber: false,
        isActive: false,
        productType: ProductType.Physic,
      };
    },
    send(): void | Promise<AxiosResponse<Product>> {
      if (!this.values) {
        return;
      }

      if (
        this.values.productType === ProductType.Forward ||
        this.values.productType === ProductType.Electronic
      ) {
        this.values.isActive = true;
      }

      this.isFormDirty = false;
      if (this.isUpdateForm) {
        return this.updateFormSend();
      }
      return this.createFormSend();
    },
    redirectToNextBulk() {
      if (
        !(this.$route.query.productIds as string[]) ||
        !(this.$route.query.clientIds as string[])
      ) {
        return;
      }

      const redirectFlow = () => {
        const index = this.$route.query.productIds.indexOf(
          this.$route.params.productId
        );
        if (
          index === this.$route.query.productIds.length - 1 ||
          !this.$route.query.productIds[index + 1]
        ) {
          this.$router.push({
            name: "product.concept",
            query: { bulkFinished: "true" },
          });
          return;
        }
        this.$router.push({
          name: "product.edit",
          params: {
            productId: this.$route.query.productIds[index + 1] as string,
            clientId: this.$route.query.clientIds[index + 1] as string,
          },
          query: {
            productIds: this.$route.query.productIds,
            clientIds: this.$route.query.clientIds,
          },
        });
      };

      if (!this.isFormDirty) {
        redirectFlow();
        return;
      }
      EventBus.$emit("confirm", {
        title: this.$t("global.confirmationTitle"),
        body: this.$t("global.confirmationContinueWithoutSaving"),
        confirmCallback: async () => {
          redirectFlow();
        },
      });
    },
    createFormSend(): Promise<AxiosResponse> {
      return send({
        product: this.values,
        clientId: this.clientId,
      });
    },
    updateFormSend(): Promise<AxiosResponse> {
      return sendById({
        product: this.values,
        productId: parseInt(this.$route.params.productId),
        clientId: this.clientId,
      });
    },
    async saveConcept() {
      this.isLoadingSave = true;
      try {
        if (!this.values) {
          return;
        }
        await this.ifUpdateFormSaveConcept();
        await this.ifCreateFormSaveConcept();
        eventBus.$emit("snackbar", this.$t("global.saveSuccess"));
        this.isFormDirty = false;
        this.redirectToTableIfNNotBulk();
      } catch (e) {
        eventBus.$emit("snackbar", {
          color: "error",
          text: this.$t("global.error"),
        });
        throw e;
      }
      this.isLoadingSave = false;
    },
    async ifUpdateFormSaveConcept() {
      if (this.isUpdateForm) {
        await conceptSaveById({
          product: this.values,
          clientId: this.clientId,
          productId: parseInt(this.$route.params.productId),
        });
      }
    },
    async ifCreateFormSaveConcept() {
      if (!this.isUpdateForm) {
        await conceptSave({
          product: this.values,
          clientId: this.clientId,
        });
      }
    },
    handleSuccess() {
      eventBus.$emit("snackbar", this.$t("product.messages.sendSuccess"));
      this.redirectToNextBulk();
      this.redirectToTableIfNNotBulk();
    },
    redirectToTableIfNNotBulk() {
      if (!(this.$route.query.productIds as string[])) {
        this.$router.push({
          name: "product.concept",
        });
      }
    },
  },
});
